<template>
  <div class="outer-page">
    <x-table
      :no-data-text="CA('quality_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
    ></x-table>
    <Modal
      v-model="modal.show"
      :title="modal.title"
      :width="500"
      @on-visible-change="modalShow"
    >
      <Form :label-width="90" ref="form" :model="form" :rules="rules">
        <FormItem label="报告名称" prop="name">
          <Input
            style="width: 350px"
            placeholder="请填写报告名称"
            v-model="form.name"
            clearable
          ></Input>
        </FormItem>
        <FormItem label="检测时间" prop="recordTime">
          <DatePicker
            type="date"
            placeholder="请选择检测时间"
            clearable
            v-model="form.recordTime"
            style="width: 350px"
          ></DatePicker>
        </FormItem>
        <FormItem label="基地" prop="farmInfo">
          <Select
            placeholder="请选择基地"
            clearable
            v-model="form.farmInfo"
            style="width: 350px"
          >
            <Option
              v-for="(item, index) in farmList"
              :key="index"
              :value="item.id + '/' + item.name"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem
          v-for="(item, index) in categoryLists"
          :key="index"
          :label="item.name"
        >
          <template>
            <Table
              :columns="getColumns"
              :data="item.monitoringIndicatorList"
              style="width: 350px"
            ></Table>
          </template>
        </FormItem>
        <FormItem label="描述">
          <Input
            clearable
            placeholder="请填写描述"
            type="textarea"
            v-model="form.remark"
            style="width: 350px"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
    <Modal v-model="checkModalShow" title="指标详情" :width="580">
      <!-- <template>
        <Table :data="recordList" :columns="recordColumns"></Table>
      </template> -->
      <Form :label-width="90">
        <FormItem
          v-for="(item, index) in recordList"
          :key="index"
          :label="item.name"
        >
          <template>
            <Table :columns="recordColumns" :data="item.values"></Table>
          </template>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "",
  components: {},
  data() {
    return {
      table: {
        columns: [
          {
            title: "报告名称",
            key: "name",
          },
          {
            title: "检测基地",
            minWidth: 200,
            key: "farmName",
          },
          {
            title: "检测时间",
            render: (h, { row }) => {
              return (
                <span>
                  {row.recordTime ? row.recordTime.split(" ")[0] : ""}
                </span>
              );
            },
          },
          {
            title: "描述",
            key: "remarks",
          },
          {
            title: "操作",
            width: 200,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("quality_update") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("quality_check") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.check(row)}
                    >
                      查看指标
                    </a>
                  )}
                  {this.CA("quality_delete") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.delete(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      recordColumns: [
        {
          title: "指标名称",
          width: 140,
          key: "name",
        },
        {
          title: "要求",
          key: "remark",
        },
        {
          title: "指标数值",
          width: 140,
          key: "value",
        },
      ],
      config: {
        filter: {
          add: {
            addBtnName: "新增报告",
            ca: "quality_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "检测基地",
              component: "select",
              field: "farmId",
              defaultValue: "",
              data: [],
              parameterField: "id",
              showField: "name",
              isCallback: true,
              mark: "1",
            },
          ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },

      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        name: "",
        vs: "",
        recordTime: "",
        remark: "",
        farmId: "",
        farmName: "",
        farmInfo: "",
      },
      rules: {
        recordTime: [{ required: true, message: "请选择检测日期" }],
        farmInfo: [{ required: true, message: "请选择基地" }],
        name: [{ required: true, message: "请填写报告名称" }],
      },
      search_data: {},
      farmList: [],
      // categoryList: [],
      //提交指标数据
      categoryValue: new Map(),
      //指标详情弹窗
      checkModalShow: false,
      //指标详情列表
      recordList: [],
      //指标分类
      categoryLists: [],
      //指标数值绑定
      targetValue: "",
    };
  },
  computed: {
    getColumns() {
      let columns = [
        {
          title: "指标名称",
          align: "center",
          width: 120,
          key: "name",
        },
        {
          title: "指标数值",
          render: (h, { row, index }) => {
            !row.val && (row.val = "");
            return (
              <div style={{ padding: "1px" }}>
                <Input
                  size="small"
                  style="width:100px"
                  clearable
                  on-on-blur={(event) => {
                    let val = event.target.value;
                    this.rowValue(val, row, index);
                  }}
                ></Input>
              </div>
            );
          },
        },
        {
          title: "操作",
          align: "center",
          width: 100,
          render: (h, { row, index }) => {
            return (
              <a on-click={() => this.delete_table_row(index, row)}>删除</a>
            );
          },
        },
      ];
      return columns;
    },
  },
  methods: {
    //基地列表
    getFarm() {
      this.$post(this.$api.BASE_MANAGE.LIST, {
        siteType: 4,
      }).then((res) => {
        this.farmList = res.list;
        this.config.filter.filterBox[0].data = res.list;
      });
    },
    search(data) {
      this.search_data = data;
      this.config.page.pageNo = 1;
      this.getList();
    },

    add() {
      this.modal = {
        show: true,
        title: "新增报告信息",
        submitLoading: false,
      };
    },

    pageChange(pageNo) {
      this.config.page.pageNo = pageNo;
      this.getList();
    },

    getList() {
      if (!this.CA("quality_check")) return;
      this.table.loading = true;
      this.$post(this.$api.QUALITY_DETECTION.LIST, {
        pageNo: this.config.page.pageNo,
        pageSize: this.config.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.config.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.farmInfo = row.farmId + "/" + row.farmName;
      this.modal = {
        show: true,
        title: "编辑报告信息",
        submitLoading: false,
      };
    },
    check(row) {
      this.recordList = row.valueList;
      this.recordChildList = row.valueList;
      this.checkModalShow = true;
    },
    delete(id) {
      this.$post(this.$api.QUALITY_DETECTION.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功！");
        this.getList();
      });
    },
    rowValue(vals, row, index) {
      let newRow = {
        monitoringId: row.id,
        value: vals,
      };
      this.categoryValue.set(newRow.monitoringId, newRow);
    },
    //删除指标
    delete_table_row(index, row) {
      this.categoryLists.forEach((item) => {
        item.monitoringIndicatorList.forEach((it) => {
          if (row.id == it.id) {
            item.monitoringIndicatorList.splice(index, 1);
            this.categoryValue.delete(row.id);
          }
        });
      });
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let arr = [...this.categoryValue.values()];
        let params = { ...this.form };
        params.vs = JSON.stringify(arr);
        params.recordTime = moment(this.form.recordTime).format("YYYY-MM-DD");
        if (this.form.farmInfo) {
          params.farmId = this.form.farmInfo.split("/")[0];
          params.farmName = this.form.farmInfo.split("/")[1];
        }
        let url;
        if (params.id) {
          url = this.$api.QUALITY_DETECTION.UPDATE;
        } else {
          url = this.$api.QUALITY_DETECTION.ADD;
        }
        this.$post(url, params)
          .then(() => {
            this.$Message.success(params.id ? "编辑成功！" : "添加成功！");
            this.getList();
            this.modal.show = false;
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },
    //指标列表-原
    // getCategoryList() {
    //   this.$post(this.$api.MONITORINGINDICATOR.LIST).then((res) => {
    //     this.categoryList = res.list;
    //   });
    // },
    //指标分组列表
    getGroup() {
      this.$post(this.$api.MONITORINGINDICATOR.GROUP).then((res) => {
        // console.log(res[0].monitoringIndicatorList);
        this.categoryLists = res;
      });
    },
    modalShow(visible) {
      if (visible) {
        // this.getCategoryList();
        this.getGroup();
        return;
      }
      this.form = {
        id: "",
        vs: "",
        name: "",
        recordTime: "",
        remark: "",
        farmId: "",
        farmName: "",
        farmInfo: "",
      };
      this.$refs.form.resetFields();
      this.categoryValue.clear();
      // this.categoryList = [];
      this.categoryLists = [];
    },
  },
  mounted() {
    this.getList();
    this.getFarm();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>